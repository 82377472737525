<template>
  <div
    v-show="show"
    class="border relative empty:hidden text-xs leading-[1.125rem]"
    :class="[
      component.size,
      component.main,
      component.width,
      customClass
    ]"
  >
    <p
      v-if="$slots.title"
      class="text-base mb-1 font-bold"
    >
      <slot name="title" />
    </p>

    <slot v-if="$slots.default" />

    <NuxtImg
      v-if="hasClose"
      ref="icon"
      src="/icons/close-cirle.svg"
      alt="close circle"
      class="absolute top-[0.5625rem] right-[0.5625rem] cursor-pointer"
      @click="show = false"
    />
  </div>
</template>

<script setup>
defineOptions({
  name: 'AtomsAlert'
})

const props = defineProps({
  size: {
    type: String,
    default: 'p1',
    validator: value => ['p1', 'p2', 'p'].includes(value)
  },

  type: {
    type: String,
    default: 'error',
    validator: value => ['info', 'warning', 'error', 'success', 'disclaimer'].includes(value)
  },

  hasClose: {
    type: Boolean,
    default: false
  },

  fullWidth: {
    type: Boolean,
    default: false
  },

  rounded: {
    type: Boolean,
    default: true
  },

  customClass: {
    type: String,
    default: ''
  }
})

const show = ref(true)

defineExpose({
  show
})

// styles
const component = computed(() => {
  return {
    size: getKey(props.size, {
      p: `py-0 px-2 ${props.hasClose ? 'pr-10' : ''}`,
      p1: `p-2 ${props.hasClose ? 'pr-10' : ''}`,
      p2: `p-4 ${props.hasClose ? 'pr-8' : ''}`
    }),

    main: (() => {
      const base = getKey(props.type, {
        info: 'bg-alert-info-fill text-dark',
        error: 'bg-alert-error-fill text-alert-error-stroke',
        warning: 'bg-alert-warning-fill text-dark',
        success: 'bg-alert-success-fill text-dark',
        disclaimer: 'bg-white text-gray-80'
      })

      const border = getKey(props.type, {
        info: 'border-alert-info-stroke',
        error: 'border-alert-error-stroke',
        warning: 'border-alert-warning-stroke',
        success: 'border-primary',
        disclaimer: 'border-primary'
      })

      return `${base} ${border} ${props.rounded ? 'rounded-lg' : ''}`
    })(),

    width: (() => {
      if (props.fullWidth) {
        return 'w-full'
      }

      return 'w-full max-w-[26.5rem] sm:max-w-[28.125rem]'
    })()
  }
})
</script>
